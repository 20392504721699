import React, { useRef, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import MarkerInfoWindow from './MarkerInfoWindow'; 

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const fetchBusinessProfiles = async () => {
  const querySnapshot = await getDocs(collection(db, 'businessprofiles'));
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const geocodeAddress = async (address) => {
  const geocoder = new window.google.maps.Geocoder();
  try {
    const response = await geocoder.geocode({ address });
    if (response.results[0]) {
      const location = response.results[0].geometry.location;
      return {
        lat: location.lat(),
        lng: location.lng()
      };
    } else {
      console.warn('No results found for address:', address);
    }
  } catch (error) {
    console.error('Error geocoding address:', error);
  }
  return null;
};

const libraries = ['marker'];

const MapComponent = ({ center, onMapLoad }) => {
  const mapRef = useRef(null);
  const mapLoaded = useRef(false); 
  const [markers, setMarkers] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null); 

  const mapStyles = { height: "80vh", width: "100%" };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  useEffect(() => {
    const loadMarkers = async () => {
      const businessProfiles = await fetchBusinessProfiles();
      const markerPromises = businessProfiles.map(async (business) => {
        const location = await geocodeAddress(business.address);
        if (location) {
          return {
            ...business,
            latitude: location.lat,
            longitude: location.lng
          };
        }
        return null;
      });
      const markerData = (await Promise.all(markerPromises)).filter(Boolean);
      setMarkers(markerData);
    };

    loadMarkers();
  }, []);

  useEffect(() => {
    if (mapLoaded.current && mapRef.current) {
      markers.forEach(async (marker) => {
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
  
        const markerContent = document.createElement("div");
        markerContent.style.display = 'flex';
        markerContent.style.flexDirection = 'column';
        markerContent.style.alignItems = 'center';
  
        const image = document.createElement("img");
        image.src = marker.profileImageUrl; 
        image.alt = marker.businessBrand;
        image.style.width = '80px'; 
        image.style.height = '80px'; 
        image.style.borderRadius = '50%'; 
        image.style.border = '4px solid black'; 
        image.style.background = 'none';
  
        const title = document.createElement("span");
        title.innerText = marker.businessBrand;
        title.style.marginTop = '5px'; 
        title.style.fontSize = '16px'; 
        title.style.textAlign = 'center';
        title.style.backgroundColor = 'rgba(0, 0, 0, 0.8)'; 
        title.style.color = 'white'; 
        title.style.padding = '5px'; 
        title.style.borderRadius = '5px'; 
        title.style.boxShadow = 'none';
        title.style.border = 'none';
  
        markerContent.appendChild(image);
        markerContent.appendChild(title);
  
        const advancedMarker = new AdvancedMarkerElement({
          map: mapRef.current,
          position: { lat: marker.latitude, lng: marker.longitude },
          content: markerContent,
          title: marker.businessBrand, 
        });
  
        
        advancedMarker.addListener('click', () => {
          setSelectedBusiness(marker);
        });
      });
    }
  }, [markers]);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={10}
      center={center}
      onLoad={map => {
        mapRef.current = map;
        mapLoaded.current = true; 
        if (onMapLoad) onMapLoad(map);
      }}
      options={{
        mapId: process.env.REACT_APP_GOOGLE_MAP_ID, 
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        controlSize: 50, 
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM
        },
        mapTypeControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
          style: window.google.maps.MapTypeControlStyle.DEFAULT, 
          mapTypeIds: ['roadmap', 'terrain'] 
        },
        scaleControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM
        },
        streetViewControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM
        },
        rotateControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM
        },
        fullscreenControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM
        }
      }}
    >
      {selectedBusiness && (
        <MarkerInfoWindow
          business={selectedBusiness}
          onClose={() => setSelectedBusiness(null)} 
        />
      )}
    </GoogleMap>
  );
};

export default MapComponent;
