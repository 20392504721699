import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './views/Home';
import PrivacyPolicy from './views/PrivacyPolicy';
import Footer from './components/Footer';
import AdPolicy from './views/AdPolicy';
import TermsService from './views/TermsService';
import Support from './views/Support';
import SearchView from './views/SearchView'; 
import BusinessSearchView from './views/BusinessSearchView';
import LoginView from './views/LoginView';
import PostManagerView from './views/PostManagerView';
import CreateEventView from './views/CreateEventView';
import NotFound from './views/NotFound';  // Import NotFound component

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/termsofservice" element={<TermsService />} />
          <Route path="/adpolicy" element={<AdPolicy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/players" element={<SearchView />} />
          <Route path="/map" element={<BusinessSearchView />} />
          <Route path="/login" element={<LoginView />} />
          <Route path="/tournaments" element={<PostManagerView />} />
          <Route path="/create-event" element={<CreateEventView />} />
          <Route path="/edit-event/:eventId" element={<CreateEventView editMode={true} />} />
          {/* Fallback 404 route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
