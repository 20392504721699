import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, getDocs, where } from 'firebase/firestore'; 
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt, faHome, faPlus, faClipboard, faStore } from '@fortawesome/free-solid-svg-icons';
import MapComponent from './MapComponent';
import SettingsView from './SettingsView';
import CreateEventView from './CreateEventView';
import PostManagerView from './PostManagerView';
import BusinessEditor from './BusinessEditor';
import { auth, firestore } from './firebaseConfig';
import './BusinessSearchView.css';

const BusinessSearchView = () => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [user, setUser] = useState(null);
  const [activeView, setActiveView] = useState('map');
  const [hasBusinessProfile, setHasBusinessProfile] = useState(false);

  const checkBusinessProfileExistence = useCallback(async (userId) => {
    try {
      const q = query(
        collection(firestore, 'businessprofiles'),
        where('userId', '==', userId)
      );
      const querySnapshot = await getDocs(q);
      setHasBusinessProfile(!querySnapshot.empty);
    } catch (error) {
      console.error('Error checking business profile:', error);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        checkBusinessProfileExistence(currentUser.uid);
      } else {
        setHasBusinessProfile(false);
      }
    });
    return () => unsubscribe();
  }, [checkBusinessProfileExistence]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setHasBusinessProfile(false);
      window.location.reload(); 
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }; 

  const geocodeAddress = async (address) => {
    const geocoder = new window.google.maps.Geocoder();
    try {
      const response = await geocoder.geocode({ address });
      if (response.results[0]) {
        const location = response.results[0].geometry.location;
        return {
          lat: location.lat(),
          lng: location.lng()
        };
      } else {
        console.warn('No results found for address:', address);
      }
    } catch (error) {
      console.error('Error geocoding address:', error);
    }
    return null;
  };

  const handleSearch = useCallback(async (search) => {
    if (!search.trim()) {
      setResults([]);
      setMarkers([]);
      return;
    }

    try {
      const q = query(collection(firestore, 'businessprofiles'));
      const querySnapshot = await getDocs(q);

      const allBusinesses = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const filteredBusinesses = allBusinesses.filter(business =>
        business.businessBrand.toLowerCase().includes(search.toLowerCase()) ||
        business.address.toLowerCase().includes(search.toLowerCase())
      );

      const businessesWithCoordinates = await Promise.all(filteredBusinesses.map(async (business) => {
        const location = await geocodeAddress(business.address);
        return location ? {
          ...business,
          latitude: location.lat,
          longitude: location.lng
        } : null;
      }));

      const validBusinesses = businessesWithCoordinates.filter(business => business !== null);

      setResults(validBusinesses);
      setMarkers(validBusinesses.map(business => ({
        id: business.id,
        latitude: parseFloat(business.latitude),
        longitude: parseFloat(business.longitude),
        title: business.businessBrand 
      })));
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  }, [firestore]);

  const handleMarkerClick = (marker) => {
    if (!isNaN(marker.latitude) && !isNaN(marker.longitude)) {
      setSelectedLocation({ lat: marker.latitude, lng: marker.longitude });
    } else {
      console.error('Invalid marker coordinates:', marker);
    }
  };

  const handleMapLoad = (map) => {
    setMapInstance(map);
  };

  const handleResultClick = (business) => {
    handleMarkerClick({
      latitude: parseFloat(business.latitude),
      longitude: parseFloat(business.longitude),
      title: business.businessBrand
    });
    setSearchText(''); 
  };

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText, handleSearch]);

  useEffect(() => {
    if (mapInstance && selectedLocation) {
      mapInstance.panTo(selectedLocation);
      mapInstance.setZoom(14);
    }
  }, [selectedLocation, mapInstance]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderActiveView = () => {
    switch (activeView) {
      case 'settings':
        return <SettingsView />;
      case 'post':
        return <CreateEventView setActiveView={setActiveView} />;
      case 'postmanager':
        return <PostManagerView />;
      case 'businesseditor':
        return <BusinessEditor />;
      case 'map':
      default:
        return (
          <>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search by business brand or address..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            {results.length > 0 && (
              <div className="results-overlay">
                {results.map(business => (
                  <div
                    key={business.id}
                    className="result-item"
                    onClick={() => handleResultClick(business)}
                  >
                    <div className="result-image">
                      <img src={business.profileImageUrl} alt={business.businessBrand} className="profile-image" />
                    </div>
                    <div className="result-info">
                      <div className="result-title">{business.businessBrand}</div>
                      <p>Address: {business.address}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <MapComponent
              markers={markers}
              onMarkerClick={handleMarkerClick}
              center={selectedLocation || { lat: 37.7749, lng: -122.4194 }} 
              onMapLoad={handleMapLoad}
            />
          </>
        );
    }
  };
  
  return (
    <div className="business-search-view">
      <div className="auth-section">
        {user ? (
          <div className="toolbar">
            <button onClick={() => setActiveView('map')} className="toolbar-icon">
              <FontAwesomeIcon icon={faHome} />
            </button>
            {hasBusinessProfile && (
              <>
                <button onClick={() => setActiveView('post')} className="toolbar-icon">
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <button onClick={() => setActiveView('postmanager')} className="toolbar-icon">
                  <FontAwesomeIcon icon={faClipboard}/>
                  <span className="toolbar-icon-text">Posts</span>
                </button>
                <button onClick={() => setActiveView('businesseditor')} className="toolbar-icon">
                  <FontAwesomeIcon icon={faStore} />
                  <span className="toolbar-icon-text">Business</span>
                </button>
              </>
            )}
            <button onClick={() => setActiveView('settings')} className="toolbar-icon">
              <FontAwesomeIcon icon={faCog} />
            </button>
            <button onClick={handleSignOut} className="toolbar-icon">
              <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
          </div>
        ) : (
          <button onClick={() => window.location.href = '/login'} className="login-button">
            Login
          </button>
        )}
      </div>
      <div className="view-content">
        {renderActiveView()}
      </div>
    </div>
  );
  
};

export default BusinessSearchView;
