import React, { useEffect } from 'react';
import Spacer from './Spacer';
import './policy.css';

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="policy-container py-5">
      <header>
        <h1 className="text-center mb-4">Privacy Policy</h1>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 col-md-10 col-sm-12">
              <p className="lead">
                <strong>Privacy Policy</strong>
                <Spacer />
                Effective Date: [5-22-2024]
                <Spacer />
                Thank you for using Pool 4 Real. This policy outlines how information is handled when you use the mobile application. Please read it carefully. By accessing or using the app, you agree to the terms outlined herein.
                <Spacer />
                1. Information Collection
                <Spacer />
                The following information is collected:
                <Spacer />
                Personal Information:
                When you create an account, your email address is collected. When using the app, location is collected if you opt in for some app features.
                For businesses, business email, address, and legal names for authentication purposes are collected.
                <Spacer />
                Content Information:
                User-generated content, including messages, profile information, and listings.
                <Spacer />
                2. Uses of Information
                <Spacer />
                Usages of collected information:
                <Spacer />
                Provide and improve our services.
                Personalize user experiences.
                Communicate with users.
                Comply with legal obligations.
                <Spacer />
                3. Third-Party Services
                <Spacer />
                We may use third-party services, such as analytics tools, to analyze user data for service improvement. Personal information is not sold.
                <Spacer />
                4. Data Retention
                <Spacer />
                We retain user information as long as necessary for the purposes outlined, unless required by law.
                <Spacer />
                5. Security Measures
                <Spacer />
                Measures are implemented to safeguard user information against unauthorized access or disclosure.
                <Spacer />
                6. User Rights
                <Spacer />
                Users have the right to access, update, or delete their information. Contact us if you need assistance.
                <Spacer />
                7. Changes to the Privacy Policy
                <Spacer />
                Changes may happen from time to time and users will be notified when they happen.
                <Spacer />
                8. Blocking Users and Content
                <Spacer />
                Pool 4 Real provides users with the ability to block other users & flag specific content within the application. This feature is designed to empower users to manage their interactions and control their experience. 
                When you block a user their listings and conversations will get filtered out and you wont be visible to each other anymore. You can revert this by going to "My Settings" and manage your blockes users.                
                <Spacer />
                9. Data Sharing
                <Spacer />
                Pool 4 Real does not share your location or email address with other users or third parties unless explicitly stated otherwise in this policy.
                <Spacer />
                10. Legal Compliance
                <Spacer />
                Our method strives to comply with all applicable data privacy laws and regulations.
                <Spacer />
                11. Contact Us
                <Spacer />
                For questions or concerns about our Privacy Policy, contact us at <a href="mailto:rack@pool4real.com">rack@pool4real.com</a>.
                <Spacer />
                12. Session Persistence
                <Spacer />
                If you uninstall and then reinstall Pool 4 Real, your login session may persist. This is to enhance user experience by allowing you to continue where you left off. If you prefer to start fresh or ensure complete log-out, please manually sign out before uninstalling the app.
                <Spacer />
                13. Data Storage and Deletion
                <Spacer />
                User data is stored securely on our servers. If you uninstall the app, your data remains stored unless you delete your account & data from "My Settings" inside the app or request deletion by contacting us at <a href="mailto:rack@pool4real.com">rack@pool4real.com</a>. Reinstalling the app will restore your previous session and data unless you have requested data deletion.
              </p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default PrivacyPolicy;
