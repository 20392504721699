import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app'; 
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import './MarkerInfoWindow.css';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const fetchTournaments = async (businessId) => {
  const q = query(
    collection(db, 'tournaments'),
    where('businessId', '==', businessId),
    where('status', '==', 'active')
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

const MarkerInfoWindow = ({ business, onClose }) => {
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    const loadTournaments = async () => {
      if (business?.id) {
        const tournamentsData = await fetchTournaments(business.id);
        setTournaments(tournamentsData);
      }
    };

    loadTournaments();
  }, [business]);

  const handleCall = () => {
    window.location.href = `tel:${business.phone}`;
  };

  const handleEmail = () => {
    window.location.href = `mailto:${business.businessEmail}`;
  };

  const handleAddress = () => {
    const addressQuery = encodeURIComponent(business.address);
    window.open(`https://www.google.com/maps/search/?api=1&query=${addressQuery}`, '_blank');
  };
  

  return (
    <div className="marker-info-window-custom">
      <button onClick={onClose} className="marker-info-window-custom__close-button">X</button>
      <div className="marker-info-window-custom__content">
        <div className="marker-info-window-custom__business-details">
          <h2>{business.businessBrand}</h2>
          <img src={business.secondImageUrl} alt={business.businessBrand} className="marker-info-window-custom__image" />
          <p>
            <strong>Address:</strong> <span onClick={handleAddress} className="marker-info-window-custom__link">{business.address}</span>
          </p>
          <p>
            <strong>Phone:</strong> <span onClick={handleCall} className="marker-info-window-custom__link">{business.phone}</span>
          </p>
          <p>
            <strong>Email:</strong> <span onClick={handleEmail} className="marker-info-window-custom__link">{business.businessEmail}</span>
          </p>
        </div>
        <div className="marker-info-window-custom__tournaments-list">
          {tournaments.length > 0 ? (
            tournaments.map(tournament => (
              <div key={tournament.eventId} className="marker-info-window-custom__tournament-item">
                <h3>{tournament.title}</h3>
                <p><strong>Prize:</strong> {tournament.prize}</p>
                <p><strong>Date:</strong> {new Date(tournament.dateTime.toDate()).toLocaleString()}</p>
                <p><strong>Description:</strong> {tournament.description}</p>
              </div>
            ))
          ) : (
            <p>Venue has no events, check later.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MarkerInfoWindow;
