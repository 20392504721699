import React, { useState, useEffect, useCallback } from 'react';
import { getAuth, sendPasswordResetEmail as firebaseSendPasswordResetEmail, signOut as firebaseSignOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, collection, query, where, getDocs, serverTimestamp, FieldValue } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './SettingsView.css'; 
import BusinessEditor from './BusinessEditor'; 


const SettingsView = () => {
  const auth = getAuth();
  const firestore = getFirestore();
  const storage = getStorage();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');
  const [homeBase, setHomeBase] = useState('');
  const [onlineStatus, setOnlineStatus] = useState('online');
  const [coachStatus, setCoachStatus] = useState('on');
  const [selectedImage, setSelectedImage] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [businessProfileExists, setBusinessProfileExists] = useState(false);
  const [userProfileExists, setUserProfileExists] = useState(false);
  const [view, setView] = useState('settings');
  const [profileImageUrl, setProfileImageUrl] = useState('');

  const loadUserProfile = useCallback(async () => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const userProfilesDoc = doc(firestore, 'userprofiles', userId);
      try {
        const docSnap = await getDoc(userProfilesDoc);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setName(data?.name || '');
          setNickname(data?.nickname || '');
          setHomeBase(data?.homeBase || '');
          setEmail(data?.email || '');
          setOnlineStatus(data?.onlineStatus || 'online');
          setCoachStatus(data?.coachStatus || 'on');
          if (data?.profileImageUrl) {
            setProfileImageUrl(data.profileImageUrl); 
          }
          setUserProfileExists(true);
        } else {
          setUserProfileExists(false);
        }
      } catch (error) {
        console.log(`Error getting user profile: ${error.message}`);
      }
    }
  }, [auth, firestore]);

  const checkBusinessProfileExistence = useCallback(() => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const businessProfilesDoc = doc(firestore, 'businessprofiles', userId);
      getDoc(businessProfilesDoc)
        .then((doc) => {
          setBusinessProfileExists(doc.exists());
        })
        .catch((error) => {
          console.log(`Error fetching business profile: ${error.message}`);
        });
    }
  }, [auth, firestore]);

  useEffect(() => {
    loadUserProfile();
    checkBusinessProfileExistence();
  }, [loadUserProfile, checkBusinessProfileExistence]);

  const isValidEmail = (email) => {
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegEx.test(email);
  };

  const sendPasswordResetEmail = () => {
    if (isValidEmail(email)) {
      firebaseSendPasswordResetEmail(auth, email)
        .then(() => {
          console.log('Password reset email sent successfully');
          setResetEmailSent(true);
          resetErrors();
        })
        .catch((error) => {
          console.log(`Error sending password reset email: ${error.message}`);
        });
    } else {
      setEmailError(true);
    }
  };

  const resetErrors = () => {
    setTimeout(() => {
      setEmailError(false);
      setResetEmailSent(false);
    }, 6000);
  };

  const signOut = () => {
    deleteLocationAndDeviceToken(() => {
      firebaseSignOut(auth)
        .then(() => {
          console.log('Signed out successfully');
        })
        .catch((error) => {
          console.log(`Error signing out: ${error.message}`);
        });
    });
  };

  const deleteLocationAndDeviceToken = (completion) => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const userProfilesDoc = doc(firestore, 'userprofiles', userId);
      const iosFCMTokensQuery = query(collection(firestore, 'iosFCMtokens'), where('userID', '==', userId));
      const batch = firestore.batch();

      batch.update(userProfilesDoc, { location: FieldValue.delete() });

      getDocs(iosFCMTokensQuery)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
          return batch.commit();
        })
        .then(() => {
          console.log('Location and device token deleted successfully');
          completion();
        })
        .catch((error) => {
          console.log(`Error deleting location and device token: ${error.message}`);
          completion();
        });
    } else {
      completion();
    }
  };

  const uploadImageToFirebaseStorage = (userId, imageUrl) => {
    const storageRef = ref(storage, `profileImages/${userId}`);
    return fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => uploadBytes(storageRef, blob))
      .then(() => getDownloadURL(storageRef))
      .catch((error) => {
        console.log(`Error uploading image: ${error.message}`);
        throw error;
      });
  };

  const saveOrUpdateUserProfile = () => {
    if (name && nickname && homeBase && email) {
      setIsUpdating(true);
      const userId = auth.currentUser?.uid;
      if (userId) {
        const userProfilesDoc = doc(firestore, 'userprofiles', userId);

        getDoc(userProfilesDoc)
          .then((docSnapshot) => {
            const isNewProfile = !docSnapshot.exists();
            const userData = {
              name,
              nickname,
              nickname_lowercase: nickname.toLowerCase(),
              nickname_words: preprocessNickname(nickname),
              homeBase,
              email,
              playerId: userId,
              onlineStatus: isNewProfile ? 'offline' : onlineStatus,
              coachStatus: isNewProfile ? 'off' : coachStatus,
              timeUpdated: serverTimestamp(),
            };

            if (onlineStatus !== 'offline') {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  userData.location = new firestore.GeoPoint(position.coords.latitude, position.coords.longitude);
                  saveData(userProfilesDoc, userData);
                },
                (error) => {
                  console.log(`Error getting location: ${error.message}`);
                  saveData(userProfilesDoc, userData);
                }
              );
            } else {
              saveData(userProfilesDoc, userData);
            }
          })
          .catch((error) => {
            console.log(`Error fetching user profile: ${error.message}`);
            setIsUpdating(false);
          });
      }
    } else {
      console.log('All fields are required.');
    }
  };

  const saveData = (userProfilesDoc, userData) => {
    setDoc(userProfilesDoc, userData, { merge: true })
      .then(() => {
        console.log('User profile saved successfully');
        if (selectedImage) {
          uploadImageToFirebaseStorage(userData.playerId, selectedImage)
            .then((imageUrl) => {
              return setDoc(userProfilesDoc, { profileImageUrl: imageUrl }, { merge: true });
            })
            .then(() => {
              console.log('Image URL saved successfully');
              setIsUpdating(false);
            })
            .catch((error) => {
              console.log(`Error uploading image: ${error.message}`);
              setIsUpdating(false);
            });
        } else {
          setIsUpdating(false);
        }
      })
      .catch((error) => {
        console.log(`Error saving user profile: ${error.message}`);
        setIsUpdating(false);
      });
  };

  const preprocessNickname = (nickname) => {
    const words = nickname.toLowerCase().split(' ').filter(word => word.length >= 3);
    const allowedCharacters = /[a-zA-Z0-9?@,.-_+'!()]/;
    const allowedCharacters2 = /[0-9,.]/;
    let substrings = [];

    words.forEach(word => {
      if (word.length >= 3) {
        for (let i = 3; i <= word.length; i++) {
          for (let j = 0; j <= word.length - i; j++) {
            const substring = word.substring(j, j + i);
            if (allowedCharacters.test(substring)) {
              substrings.push(substring);
            }
          }
        }
      }
    });

    substrings = Array.from(new Set(substrings)).filter(substring => allowedCharacters2.test(substring) || substring.length >= 3);
    return substrings;
  };

  const handleViewChange = () => {
    setView('businesseditor');
  };

  const handleImageClick = () => {
    document.getElementById('file-input').click();
  };

  return (
    <div className="custom-settings-view">
      <div className="custom-header-padding" />
      <div className="custom-editor-content">
        <h1>My Settings</h1>
  
        {view === 'settings' ? (
          <div className="custom-edit-form">
            <div className="custom-image-row">
              <p className="custom-image-label">Profile Image</p>
              <div className="custom-profile-image-container" onClick={handleImageClick}>
  <div className="custom-profile-image-preview-container">
    <img
      className="custom-profile-image-preview"
      src={profileImageUrl || 'default-profile.png'}
      alt="Profile"
    />
  </div>
</div>

<input
  id="file-input"
  type="file"
  onChange={(e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setSelectedImage(previewUrl);
      setProfileImageUrl(previewUrl);
    }
  }}
  className="custom-file-input"
/>

            </div>
  
            <label className="custom-label">
              Name (Not Public)
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </label>
  
            <label className="custom-label">
              Nickname
              <input
                type="text"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                placeholder="Nickname"
              />
            </label>
  
            <label className="custom-label">
              Home Base
              <input
                type="text"
                value={homeBase}
                onChange={(e) => setHomeBase(e.target.value)}
                placeholder="Home Base"
              />
            </label>
  
            <label className="custom-label">
              Email (Not Public)
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              {emailError && <span className="error">Invalid email address</span>}
            </label>
  
            <button className="custom-save-button" type="button" onClick={saveOrUpdateUserProfile} disabled={isUpdating}>
              {isUpdating ? 'Updating...' : 'Save'}
            </button>
  
            <button className="custom-cancel-button" type="button" onClick={signOut}>
              Sign Out
            </button>
  
            <div className="custom-reset-password-link" onClick={sendPasswordResetEmail}>
              Reset Password
            </div>
  
            {resetEmailSent && <p>Password reset email sent</p>}
  
            {userProfileExists && !businessProfileExists && (
              <button className="custom-create-business-profile-button" type="button" onClick={handleViewChange}>
                Create Business Profile
              </button>
            )}
          </div>
        ) : (
          <BusinessEditor />
        )}
      </div>
    </div>
  );
  

  
};

export default SettingsView;
