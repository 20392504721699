import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { auth, firestore } from './firebaseConfig';
import './CreateEventView.css';

const CreateEventView = ({ editMode = false, eventId, onClose, setActiveView }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [fee, setFee] = useState('');
  const [rules, setRules] = useState('');
  const [numPlayers, setNumPlayers] = useState('');
  const [prize, setPrize] = useState('');
  const [ballType, setBallType] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState(''); 
  const [error, setError] = useState('');
  const [businessProfile, setBusinessProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(editMode);

  useEffect(() => {
    const fetchBusinessProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(firestore, 'businessprofiles', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBusinessProfile(docSnap.data());
        } else {
          setError('Business profile not found.');
        }
      }
    };

    const fetchEventData = async () => {
      if (editMode && eventId) {
        try {
          const docRef = doc(firestore, 'tournaments', eventId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const eventData = docSnap.data();
            setTitle(eventData.title);
            setDescription(eventData.description);
            setFee(eventData.fee);
            setRules(eventData.rules);
            setNumPlayers(eventData.numPlayers);
            setPrize(eventData.prize);
            setBallType(eventData.ballType);
            setSelectedDate(eventData.dateTime.toDate().toISOString().slice(0, 10));
            setSelectedTime(eventData.dateTime.toDate().toISOString().slice(11, 16)); 
            setIsLoading(false);
          } else {
            setError('Event not found.');
          }
        } catch (error) {
          console.error('Failed to load event data:', error);
          setError('Failed to load event data.');
        }
      }
    };

    fetchBusinessProfile();
    fetchEventData();
  }, [editMode, eventId]);

  const isPostButtonDisabled = () => {
    const currentDate = new Date();
    const selectedDateTime = new Date(`${selectedDate}T${selectedTime}`);
    const timeDifference = (selectedDateTime - currentDate) / 60000; 

    return (
      timeDifference < 30 ||
      title.trim() === '' ||
      description.trim() === '' ||
      fee.trim() === '' ||
      rules.trim() === '' ||
      numPlayers.trim() === '' ||
      prize.trim() === '' ||
      ballType.trim() === '' ||
      !selectedDate ||
      !selectedTime
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isPostButtonDisabled()) {
      setError('Please ensure all fields are filled and the event is at least 30 minutes in the future.');
      return;
    }

    try {
      const currentUserID = auth.currentUser?.uid;
      if (!currentUserID || !businessProfile) {
        setError('Failed to fetch user or business profile data.');
        return;
      }

      const eventData = {
        title,
        description,
        fee,
        rules,
        numPlayers,
        prize,
        ballType,
        dateTime: new Date(`${selectedDate}T${selectedTime}`),
        timestamp: serverTimestamp(),
        status: 'active',
        businessId: currentUserID,
        address: businessProfile.address || '',
        businessEmail: businessProfile.businessEmail || '',
        businessName: businessProfile.businessName || '',
        businessBrand: businessProfile.businessBrand || ''
      };

      if (editMode) {
        const docRef = doc(firestore, 'tournaments', eventId);
        await updateDoc(docRef, eventData);
      } else {
        const tournamentsCollection = collection(firestore, 'tournaments');
        const newDocRef = await addDoc(tournamentsCollection, eventData);
        await updateDoc(newDocRef, { eventId: newDocRef.id });
      }

      setActiveView('postmanager');
      onClose();
    } catch (error) {
      console.error('Error creating or updating the event:', error);
      setError('Error creating or updating the event. Please try again.');
    }
  };

  if (isLoading) {
    return <p>Loading event data...</p>;
  }

  return ( 
    <div className="modal-container">
      <div className="header-padding"></div>
      <div className="modal-content">
        <div className="modal-header">
          <h1>{editMode ? 'Edit Event' : 'Create a New Event'}</h1>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit} className="event-form">
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="input-field"
          />
          <textarea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="input-field textarea-field"
          />
          <div className="date-time-picker">
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              className="input-field"
            />
            <input
              type="time"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              className="input-field"
            />
          </div>
          <input
            type="text"
            placeholder="Fee"
            value={fee}
            onChange={(e) => setFee(e.target.value)}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Rules"
            value={rules}
            onChange={(e) => setRules(e.target.value)}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Number of Players"
            value={numPlayers}
            onChange={(e) => setNumPlayers(e.target.value)}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Prize"
            value={prize}
            onChange={(e) => setPrize(e.target.value)}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Ball Type"
            value={ballType}
            onChange={(e) => setBallType(e.target.value)}
            className="input-field"
          />
          <button
            type="submit"
            className={`submit-button ${isPostButtonDisabled() ? 'disabled' : ''}`}
            disabled={isPostButtonDisabled()}
          >
            {editMode ? 'Update Event' : 'Create Event'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateEventView;
