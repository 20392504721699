import React from 'react';
import { FaTiktok, FaFacebook } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer py-4" style={{ background: 'black', color: 'white', textAlign: 'center' }}>
      <div className="container mb-5">
        <div className="row justify-content-center">
        
  <div className="col-md-4 mt-2">
  <h5>Links</h5>
  <ul className="list-unstyled">

  <li className="d-flex justify-content-center"><Link to="/" className="text-white">Home</Link></li>
  <li className="d-flex justify-content-center"><Link to="map" className="text-white">Real Map</Link></li>
  <li className="d-flex justify-content-center"><Link to="/privacy" className="text-white">Privacy Policy</Link></li>
  <li className="d-flex justify-content-center"><Link to="/termsofservice" className="text-white">Terms of Service</Link></li>
  <li className="d-flex justify-content-center"><Link to="/adpolicy" className="text-white">Advertising Policy</Link></li>

  </ul>
</div>

<div className="col-md-4 mt-2">
  <h5>Need Help?</h5>
  <li className="d-flex justify-content-center"><Link to="/support" className="text-white">Support</Link></li>
</div>


<div className="col-md-4 mt-2">
            <h5>What's going on...</h5>
            <div className="d-flex justify-content-center">
              <a href="https://www.tiktok.com/@pool4real" className="text-white me-4"><FaTiktok size={40} /></a>
              <a href="https://www.facebook.com/people/Pool-4-Real/61558102206785" className="text-white me-4"><FaFacebook size={40} /></a>
            </div>
          </div>


        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-md-12 mb-5">
            <p>&copy; 2024 Pool4Real. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
