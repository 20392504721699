import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, firestore, storage } from './firebaseConfig';
import './BusinessEditor.css';

const BusinessEditor = () => {
  const [businessData, setBusinessData] = useState(null);
  const [formData, setFormData] = useState({
    businessBrand: '',
    address: '',
    phone: '',
    businessEmail: '',
    poolTables: '',
    profileImageUrl: '',
    secondImageUrl: '',
    businessOfferingFields: {
      hasAlcohol: false,
      hasSmokingLounge: false,
      hasFood: false,
      has8Ball: false,
      has9Ball: false,
      hasCarambola: false,
      hasRussianPyramid: false,
      hasSnooker: false,
    },
  });
  const [editing, setEditing] = useState(false);
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const [selectedSecondImage, setSelectedSecondImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState('');
  const [secondImagePreview, setSecondImagePreview] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  const profileImageInputRef = useRef(null);
  const secondImageInputRef = useRef(null);

  useEffect(() => {
    const fetchBusinessData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(firestore, 'businessprofiles', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setBusinessData(data);
          setFormData(data);
        }
      }
    };
    fetchBusinessData();
  }, []);

  useEffect(() => {
    if (selectedProfileImage) {
      const profileImageUrl = URL.createObjectURL(selectedProfileImage);
      setProfileImagePreview(profileImageUrl);
    } else {
      setProfileImagePreview('');
    }

    if (selectedSecondImage) {
      const secondImageUrl = URL.createObjectURL(selectedSecondImage);
      setSecondImagePreview(secondImageUrl);
    } else {
      setSecondImagePreview('');
    }
  }, [selectedProfileImage, selectedSecondImage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      businessOfferingFields: {
        ...prevData.businessOfferingFields,
        [name]: checked,
      },
    }));
  };

  const handleImageUpload = async (image, path) => {
    if (image) {
      const storageRef = ref(storage, `businessMedia/${auth.currentUser.uid}/${path}`);
      await uploadBytes(storageRef, image);
      const url = await getDownloadURL(storageRef);
      return url;
    }
    return '';
  };

  const handleSave = async () => {
    setIsSaving(true);
    setError('');

    const requiredFields = ['businessBrand', 'address', 'phone', 'businessEmail', 'poolTables'];
    for (const field of requiredFields) {
      if (!formData[field]) {
        setError(`Please fill out the ${field} field.`);
        setIsSaving(false);
        return;
      }
    }

    try {
      const updatedData = { ...formData };
      if (selectedProfileImage) {
        updatedData.profileImageUrl = await handleImageUpload(selectedProfileImage, 'profileImage.png');
      }
      if (selectedSecondImage) {
        updatedData.secondImageUrl = await handleImageUpload(selectedSecondImage, 'businessImage.png');
      }

      const user = auth.currentUser;
      if (user) {
        const docRef = doc(firestore, 'businessprofiles', user.uid);
        if (businessData) {
          await updateDoc(docRef, updatedData);
        } else {
          await setDoc(docRef, updatedData);
        }
        setBusinessData(updatedData);
        setEditing(false);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      setError('Failed to save data. Please try again.');
    }

    setIsSaving(false);
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (type === 'profile') {
      setSelectedProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file));
      if (profileImageInputRef.current) {
        profileImageInputRef.current.value = '';
      }
    } else if (type === 'second') {
      setSelectedSecondImage(file);
      setSecondImagePreview(URL.createObjectURL(file));
      if (secondImageInputRef.current) {
        secondImageInputRef.current.value = '';
      }
    }
  };


 return (
  <div className="business-editor">
    <div className="header-padding" /> 
    <div className="editor-content">

    <h1>Business Profile</h1>

    {editing ? (
  <div className="edit-form">
    {error && <p className="error-message">{error}</p>}
    <div className="image-row">

      {/* Main Profile Image */}
      <div className="business-image-container">
        <div className="business-image-preview-container">
          <img
            src={secondImagePreview || (businessData?.secondImageUrl || 'default-business-image.png')}
            alt="Business Preview"
            className="business-image"
            onClick={() => secondImageInputRef.current.click()} // Trigger file input on image click
          />
          <input
            type="file"
            onChange={(e) => handleFileChange(e, 'second')}
            accept="image/*"
            ref={secondImageInputRef}
            className="file-input"
          />
        </div>
      </div>

      {/* Map Icon */}
      <div className="map-icon-container">
        <div className="map-icon-preview-container">
          <img
            src={profileImagePreview || (businessData?.profileImageUrl || 'default-profile-image.png')}
            alt="Map Icon Preview"
            className="map-icon-image"
            onClick={() => profileImageInputRef.current.click()} // Trigger file input on image click
          />
          <input
            type="file"
            onChange={(e) => handleFileChange(e, 'profile')}
            accept="image/*"
            ref={profileImageInputRef}
            className="file-input"
          />
        </div>
      </div>
    </div>

    {/* Form Fields */}
    <label>
      Business Brand
      <input
        type="text"
        name="businessBrand"
        value={formData.businessBrand}
        onChange={handleInputChange}
        placeholder="Business Brand"
      />
    </label>

    <label>
      Address
      <input
        type="text"
        name="address"
        value={formData.address}
        onChange={handleInputChange}
        placeholder="Address"
      />
    </label>

    <label>
      Phone
      <input
        type="text"
        name="phone"
        value={formData.phone}
        onChange={handleInputChange}
        placeholder="Phone"
      />
    </label>

    <label>
      Business Email
      <input
        type="email"
        name="businessEmail"
        value={formData.businessEmail}
        onChange={handleInputChange}
        placeholder="Business Email"
      />
    </label>

    <label>
      Pool Tables
      <input
        type="text"
        name="poolTables"
        value={formData.poolTables}
        onChange={handleInputChange}
        placeholder="Pool Tables"
      />
    </label>

    <div className="toggles">
      {Object.keys(formData.businessOfferingFields).map((field) => (
        <label key={field} className="ios-switch">
          <input
            type="checkbox"
            name={field}
            checked={formData.businessOfferingFields[field]}
            onChange={handleToggleChange}
          />
          <span className="slider round"></span>
          {field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
        </label>
      ))}
    </div>

    <button className="save-button" onClick={handleSave} disabled={isSaving}>
      {isSaving ? 'Saving...' : 'Save'}
    </button>
    <button className="cancel-button" onClick={() => setEditing(false)}>Cancel</button>
  </div>
) : (
  <div className="view-data">
    <div className="image-row">

      {/* Main Profile Image */}
      <div className="business-image-container">
        <div className="business-image-preview-container">
          <img
            src={businessData?.secondImageUrl || 'default-business-image.png'}
            alt="Business Image"
            className="business-image"
            onClick={() => setEditing(true)} // Edit mode on image click
          />
        </div>
      </div>


            {/* Map Icon */}
            <div className="map-icon-container">
              <div className="map-icon-preview-container">
                <img
                  src={businessData?.profileImageUrl || 'default-profile-image.png'}
                  alt="Business Profile"
                  className="map-icon-image"
                  onClick={() => setEditing(true)} // Edit mode on image click
                />
              </div>
            </div>
          </div>

    <h2>{businessData?.businessBrand || 'New Business'}</h2>
    <p>Address: {businessData?.address || 'N/A'}</p>
    <p>Phone: {businessData?.phone || 'N/A'}</p>
    <p>Email: {businessData?.businessEmail || 'N/A'}</p>
    <p>Pool Tables: {businessData?.poolTables || 'N/A'}</p>
    <div className="toggles">
      {Object.keys(businessData?.businessOfferingFields || {}).map((field) => (
        <p key={field}>
          {field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}: {businessData?.businessOfferingFields[field] ? 'Yes' : 'No'}
        </p>
      ))}
    </div>
    <button className="edit-button" onClick={() => setEditing(true)}>Edit</button>
  </div>
)}




    </div>
  </div>
);

  
};

export default BusinessEditor;
