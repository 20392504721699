import React, { useEffect } from 'react';
import Spacer from './Spacer';
import './policy.css';

function AdPolicy() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div className="policy-container py-5">
      <header>
        <h1 className="text-center mb-4">Advertising Policy</h1>
        <div className="row justify-content-center">
          <div className="col-xl-6 col-md-10 col-sm-12">
            <p className="lead">
              While Pool 4 Real makes its best effort to prevent unauthorized advertising and serves to a 21 year old and beyond audience, all advertisers must ensure their content comply with the General Rules and with the Federal Regulations set forth by the Alcohol and Tobacco Tax and Trade Bureau (TTB). You can find their full advertising resources here: <a href="https://www.ttb.gov/">https://www.ttb.gov/</a>
              <Spacer />
              Advertisers are responsible for the content of their advertisements and for ensuring they comply with this policy and their local policies. Pool 4 Real reserves the right to remove or revoke any ad at any time.
            </p>
            <h2 className="text-center mb-4">General Rules</h2>
            <p className="lead">
              <strong>Prohibited Content:</strong>
              <Spacer />
              Strictly prohibit advertisements include but not limited to those that contain exaggerated benefits, false promises, or lack the necessary evidence to support their claims."get-rich-quick schemes," miracle weight loss solutions, and unproven health remedies. Any type of pornography, violence and any other not allowed by local law.               
              <Spacer />
              <strong>How We Identify These Practices:</strong>
              <Spacer />
              We identify potentially deceptive and non-compliant ads through internal reviews and external resources.
              <Spacer />
              <strong>Our Commitment to You:</strong>
              <Spacer />
              By maintaining these high standards, we aim to:
              <ul>
                <li>Protect users from falling victim to misleading advertisements.</li>
                <li>Uphold the integrity of the platform.</li>
                <li>Foster trust with reputable businesses seeking to connect with a genuine audience.</li>
              </ul>
              <Spacer />
              We appreciate your understanding and cooperation in creating a positive experience for everyone at Pool 4 Real.
            </p>

            <h2 className="text-center mb-4">Alcohol Related Rules</h2>
            <p className="lead">
              <strong>Content Restrictions:</strong>
              <Spacer />
              Advertisements must not promote:
              <ul>
                <li>Excessive or irresponsible drinking (e.g., partying all night, drinking contests)</li>
                <li>Underage drinking</li>
                <li>Drinking and driving</li>
                <li>Associating alcohol with activities requiring alertness or coordination (e.g., driving, operating machinery)</li>
                <li>Misrepresenting the effects of alcohol (e.g., portraying alcohol as a solution to social anxieties)</li>
                <li>Sexual content or pornography</li>
                <li>Individuals who appear underage or promote the misconception that alcohol consumption is associated with youthfulness</li>
              </ul>
              <Spacer />
              <strong>Transparency:</strong>
              <Spacer />
              If directly promoting a brand or product, advertisers must disclose the Alcohol By Volume (ABV) of their products. Advertisers are encouraged to integrate responsible drinking messages within their advertisements.
              <Spacer />
              <strong>Targeting:</strong>
              <Spacer />
              Avoid targeting users based on factors that could indicate a risk of problem drinking, such as college students, pregnant women, or locations near schools or rehabilitation centers.
              <Spacer />
              <strong>Required Ad Content:</strong>
              <Spacer />
              All advertisements must clearly identify your business/entity, including:
              <ul>
                <li>Company name</li>
                <li>Contact information (website URL or phone number)</li>
                <li>For bars and restaurants: Physical address</li>
              </ul>
              <Spacer />
              <strong>Prohibited Practices:</strong>
              <Spacer />
              Advertisements must not include:
              <ul>
                <li>False or misleading statements</li>
                <li>Claims inconsistent with approved labels</li>
                <li>Disparaging competitor products</li>
                <li>False health-related statements</li>
                <li>Misleading information about analyses, standards, or tests</li>
                <li>Misleading guarantees (money-back guarantees are allowed)</li>
                <li>Misleading organic claims</li>
              </ul>
            </p>

            <h2 className="text-center mb-4">Tobacco Advertising</h2>
            <p className="lead">
              We prohibit all advertising for tobacco products on our platform. This includes, but is not limited to:
              <ul>
                <li>Cigarettes</li>
                <li>Cigars</li>
                <li>Electronic cigarettes (e-cigarettes)</li>
                <li>Smokeless tobacco (e.g., chewing tobacco, snuff)</li>
                <li>Any other tobacco-derived product</li>
              </ul>
              <Spacer />
              Additionally, we prohibit content that:
              <ul>
                <li>Promotes the use of tobacco products in a positive light.</li>
                <li>Depicts the use of tobacco products in a way that glamorizes or normalizes it.</li>
                <li>Targets users who are likely to be underage or susceptible to tobacco use.</li>
              </ul>
              <Spacer />
              If you see an advertisement that you believe violates this policy, please report it to us at <a href="mailto:rack@pool4real.com">rack@pool4real.com</a>.
            </p>
          </div>
        </div>
      </header>
    </div>
  );
}

export default AdPolicy;
