import React, { useEffect } from 'react';
import './policy.css';

function TermsService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="policy-container py-5">
      <header>
        <h1 className="text-center mb-4">Terms of Service</h1>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 col-md-10 col-sm-12">
              <p className="lead">
                <strong>Terms of Service</strong>
                <br />
                Effective Date: [5-22-2024]
                <br /><br />
                By accessing or using Pool 4 Real, you agree to the following terms:
                <br /><br />
                1. Age Requirement
                <br />
                You must be 21 years of age or older, or of legal age to be exposed to alcohol advertisements in your jurisdiction, to use this app. Additionally, you must be of legal age to enter facilities where pool or billiards are played.
                <br /><br />
                2. Intellectual Property
                <br />
                By using this app, you consent not to use or attempt to generate similar methodologies in other digital apps to achieve the same features that Pool 4 Real offers. Any look-alike app may be subject to legal action for infringing intellectual property rights.
                <br /><br />
                3. User Conduct
                <br />
                Users agree to use the app responsibly and not engage in any activities that may harm other users or the app’s integrity.
                <br /><br />
                4. Account Termination
                <br />
                If you do not agree with these terms, you must delete your account and stop using the app immediately. If you violate any of the policies involved in the Pool 4 Real app usage, your account may be terminated at any time.
                <br /><br />
                5. Changes to Terms of Service
                <br />
                We may update these terms from time to time. Users will be notified of any changes, and continued use of the app constitutes acceptance of the new terms.
                <br /><br />
                6. Legal Compliance
                <br />
                Users agree to comply with all applicable laws and regulations while using the app. If any law enforcement agency makes a formal request, we will collaborate with the best intention to protect members of the community and the service's best interests.
                <br /><br />
                7. Contact Us
                <br />
                For questions or concerns about our Terms of Service, contact us at <a href="mailto:rack@pool4real.com">rack@pool4real.com</a>.
                <br /><br />
                8. Session Management
                <br />
                By using Pool 4 Real, you acknowledge that your session may persist even after uninstalling the app. To ensure a completely fresh start, please sign out manually before uninstalling. Reinstallation without signing out will restore your previous session and data.
                <br /><br />
                9. Shoparound Rules
                <br />
                By creating an account, users are allowed to post items in Shoparound. However, every post will be screened for prohibited content. Prohibited items include, but are not limited to:
                <br />
                - Pornography
                - Alcohol
                - Tobacco or other drugs
                - Guns/weapons
                - Ammunition
                - Non-pool/billiards related items
                <br /><br />
                Posts violating these rules may be revoked at any time at the platform’s discretion. Ensure your listings comply with these guidelines.
                <br /><br />
                10. Messaging Rules
                <br />
                Users can message among players and sellers. Messaging must comply with the following rules:
                <br />
                - No harassment, threats, or abusive language
                - No sharing of illegal or prohibited content
                - Respect the privacy and consent of others
                <br /><br />
                Users are able to block unwanted communication from any user at any time using the block/unblock feature.
                <br /><br />
                Violations may result in account suspension or termination.
                <br /><br />
                11. Advertising Policy
                <br />
                Business profiles must adhere to our <a href="/adpolicy">Advertising Policy</a> when posting advertisements. This includes ensuring that ads are truthful, not misleading, and do not violate any applicable laws.
                <br /><br />
                12. Account and Data Deletion
                <br />
                Users can delete their account and data at any time from "My Settings" within the app. For further assistance, contact us at <a href="mailto:rack@pool4real.com">rack@pool4real.com</a>. Upon deletion, all user data will be permanently removed from our servers unless required by law to retain it.
              </p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default TermsService;
