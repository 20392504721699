import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore/lite';
import './SearchView.css';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const SearchView = () => {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = async () => {
    setIsSearching(true);
    
    const lowercaseQuery = searchText.toLowerCase();
    const searchWords = lowercaseQuery.split(' ').map(word => word.trim());

    const profilesMap = new Map();

    try {
      const queryPromises = searchWords.map(word => {
        const q = query(
          collection(db, 'userprofiles'),
          where('nickname_words', 'array-contains', word)  
        );
        return getDocs(q);
      });

      const querySnapshots = await Promise.all(queryPromises);
      querySnapshots.forEach(querySnapshot => {
        querySnapshot.forEach(doc => {
          profilesMap.set(doc.id, { id: doc.id, ...doc.data() });
        });
      });

      const uniqueProfiles = Array.from(profilesMap.values());
      
      setResults(uniqueProfiles);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (searchText.trim()) {
      handleSearch();
    } else {
      setResults([]);
    }
  }, [searchText]);

  return (
    <div className="search-view">
      <input
        type="text"
        placeholder="Search by nickname..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {isSearching && <p>Searching...</p>}
      <div className="results">
        {results.length > 0 ? (
          results.map(profile => (
            <div key={profile.id} className="result-item">
              <img src={profile.profileImageUrl} alt={profile.name} className="profile-image" />
              <div className="profile-info">
                <h3>{profile.nickname}</h3>
                <p>{profile.homeBase}</p>
                <p>Status: {profile.onlineStatus}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No results found.</p>
        )}
      </div>
    </div>
  );
};

export default SearchView;
