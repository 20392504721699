import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // Updated import
import './LoginView.css'; // Import the CSS file

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

const LoginView = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Updated hook

  const handleRegister = async () => {
    setEmailError('');
    setPasswordError('');

    if (!isValidEmail(email)) {
      setEmailError('Invalid email address');
      return;
    }

    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters');
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    setIsLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(firestore, 'userReg', user.uid), {
        email,
        emailVerified: false,
        privacyTermsConsent: true, // Adjust if you need a different consent logic
        accountCreationTimestamp: new Date(),
        userId: user.uid,
      });

      await sendEmailVerification(user);

      navigate('/map'); // Redirect to another page after successful registration
    } catch (error) {
      console.error('Registration error:', error);
      setEmailError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async () => {
    setEmailError('');
    setPasswordError('');

    if (!isValidEmail(email)) {
      setEmailError('Invalid email address');
      return;
    }

    setIsLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/map'); // Redirect to another page after successful login
    } catch (error) {
      console.error('Login error:', error);
      setEmailError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidEmail = (email) => {
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegEx.test(email);
  };

  return (
    <div className="login-container">
      <h2>{isRegistering ? 'Register' : 'Login'}</h2>
      
      <form className="login-form">


        <div className="input-group">
          <div className="input-field-container">
            <label className="label" htmlFor="email">Email:</label>
            <input
              id="email"
              type="email"
              className="input-field"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <p className="error-message">{emailError}</p>}
          </div>
        </div>


        <div className="input-group">
          <div className="input-field-container">
            <label className="label" htmlFor="password">Password:</label>
            <input
              id="password"
              type="password"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && <p className="error-message">{passwordError}</p>}
          </div>
        </div>



        {isRegistering && (
          <div className="input-group">
            <div className="input-field-container">
              <label className="label" htmlFor="confirmPassword">Confirm Password:</label>
              <input
                id="confirmPassword"
                type="password"
                className="input-field"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

          </div>
        )}

        <button
          type="button"
          className={`button ${isRegistering ? 'primary' : 'secondary'}`}
          onClick={isRegistering ? handleRegister : handleLogin}
          disabled={isLoading}
        >
          {isRegistering ? 'Register' : 'Login'}
        </button>

        <div>
        
        <button
          type="button"
          className="link-button"
          onClick={() => navigate('/forgot-password')} 
        >
          Forgot Password?
        </button>
        
        </div>

        <div className="toggle-container">
          <input
            type="checkbox"
            id="toggle"
            checked={isRegistering}
            onChange={() => setIsRegistering(!isRegistering)}
          />
          <label className="toggle-label" htmlFor="toggle">
            {isRegistering ? 'Already have an account? Login' : 'Don’t have an account? Register'}
          </label>
        </div>


        <div className="continue-guest" onClick={() => navigate('/map')}>
          Continue as Guest
        </div>
        {isLoading && (
          <div className="loader">
            <div>Loading...</div>
          </div>
        )}
      </form>
    </div>
  );
};

export default LoginView;
