import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import './Home.css';

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = ['/1.png', '/2.png', '/3.png', '/4.png']; 

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); 

    return () => clearInterval(interval);
  }, [slides.length]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  return (
    <div className="home-container py5">
      <div className="row justify-content-center">
        <div className="col-md-12 px-0 mt-2">
          <p className="m-plus-rounded-1c-extrabold fs-2">Real Pool Players, this is for you!</p>

          <div className="main-image-container">
            <button className="btn-prev" onClick={prevSlide}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            </button>

            <div className="image-container img">
              <img
                src={slides[currentSlide]}
                alt={`Slide ${currentSlide + 1}`}
                className="slide-image"
              />
            </div>

            <button className="btn-next" onClick={nextSlide}>
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </button>
          </div>

          <br></br>

          <p className="m-plus-rounded-1c-bold fs-5">Pool 4 Real</p>

          <div className="download-links mt-4">
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column align-items-center me-4">
                <p className="m-plus-rounded-1c-regular fs-5">Download for iPhone:</p>
                <a
                  href="https://apps.apple.com/app/id6502504942"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-circle btn-xl"
                >
                  <FaApple className="me-0" />

                </a>
              </div>
              <div className="d-flex flex-column align-items-center">
  <p className="m-plus-rounded-1c-regular fs-5">Android Beta WebApp for Now:</p>
  <Link
    to="/map" 
    className="btn-circle btn-xl"
  >
    <FaGooglePlay className="me-0" />
  </Link>
</div>
            </div>
          </div>

          <div className="container mt-5">
            <div className="row justify-content-center">
              <div className="col-md-5 text-center mb-4">
                <div className="feature-box">
                  <img src="/poolshark.png" alt="Find Players" className="feature-image" />
                  <h4 className="mt-3">Meet the Pool Sharks</h4>
                  <p>Pool 4 Real is the first app designed for real pool/billiard players! Connect with local enthusiasts to set up 8 ball matches or just hang out.</p>
                </div>
              </div>
              <div className="col-md-5 text-center mb-4">
                <div className="feature-box">
                  <img src="/shoparound.png" alt="Shoparound" className="feature-image" />
                  <h4 className="mt-3">Shop for your game</h4>
                  <p>Pool 4 Real is also the place to monetize! a dedicated hub for buying & selling pool/billiard related items like cues, tables and more.</p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">

              <div className="col-md-5 text-center mb-4">
                <div className="feature-box">
                  <img src="/mapdemo.png" alt="Book Games" className="feature-image" />
                  <h4 className="mt-3">Discover Around</h4>
                  <p>Find Pool/Billiard Venues with tournaments and special events to be part of.</p>
                </div>
              </div>

              <div className="col-md-5 text-center mb-4">
                <div className="feature-box">
                  <img src="/poolhall.png" alt="Business Tools" className="feature-image" />
                  <h4 className="mt-3">Tools for Businesses</h4>
                  <p>Businesses can post ads, display current pool events, manage bookings & more.</p>
                </div>
              </div>


            </div>
          </div>

          <p className="m-plus-rounded-1c-bold fs-5">Pool 4 Real</p>

     
          <div className="download-links mt-4">
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column align-items-center me-4">
                <p className="m-plus-rounded-1c-regular fs-5">Download for iPhone:</p>
                <a
                  href="https://apps.apple.com/app/id6502504942"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-circle btn-xl"
                >
                  <FaApple className="me-0" />

                </a>
              </div>
              <div className="d-flex flex-column align-items-center">
  <p className="m-plus-rounded-1c-regular fs-5">Android Beta WebApp for Now:</p>
  <Link
    to="/map" 
    className="btn-circle btn-xl"
  >
    <FaGooglePlay className="me-0" />
  </Link>
</div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}

export default Home;
