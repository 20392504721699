import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; 
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  

  return (
    <nav className="navbar navbar-dark custom-navbar">

      <div className="container" ref={dropdownRef}>

        <Link className="navbar-brand" to="/" onClick={closeDropdown}>
          <img src="/logoBG1.png" alt="Pool4Real" width="110" height="40" />     
        </Link>

        <button className="custom-toggler" type="button" onClick={toggleDropdown}>
          {isOpen ? <FaChevronUp className="custom-toggler-icon" /> : <FaChevronDown className="custom-toggler-icon" />}
        </button>

        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav ms-auto">
           
          <li className="nav-item">
              <Link className="nav-link text-light" to="/map" onClick={closeDropdown}>Real Map</Link>
            </li>
           
            <li className="nav-item">
              <Link className="nav-link text-light" to="/privacy" onClick={closeDropdown}>Privacy Policy</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-light" to="/termsofservice" onClick={closeDropdown}>Terms of Service</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-light" to="/adpolicy" onClick={closeDropdown}>Advertising Policy</Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link text-light" to="/support" onClick={closeDropdown}>Support</Link>
            </li>

          </ul>
        </div>

      </div>

    </nav>
  );
}

export default Navbar;
