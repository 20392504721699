import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { firestore, auth } from './firebaseConfig';
import './PostManagerView.css';
import CreateEventView from './CreateEventView';

const PostManagerView = () => {
    const navigate = useNavigate();
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchTournaments();
    }, []);

    const fetchTournaments = () => {
        const currentUserID = auth.currentUser?.uid;
        if (!currentUserID) return;

        const q = query(
            collection(firestore, 'tournaments'),
            where('businessId', '==', currentUserID),
            where('status', 'in', ['active', 'inactive'])
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedTournaments = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const bookingMap = data.booking || {};
                const bookedUsersCount = Object.values(bookingMap).filter((status) => status === 'booked').length;
                const formattedDateTime = formatDate(data.dateTime.toDate());

                fetchedTournaments.push({
                    ...data,
                    id: doc.id,
                    bookedUsersCount,
                    formattedDateTime
                });
            });
            setTournaments(fetchedTournaments);
        });

        return () => unsubscribe();
    };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
        return date.toLocaleDateString('en-US', options);
    };

    const deleteTournament = async (tournamentId) => {
        try {
            await deleteDoc(doc(firestore, 'tournaments', tournamentId));
            console.log('Tournament deleted successfully');
        } catch (error) {
            console.error('Error deleting tournament:', error);
        }
    };

    const handleEdit = (tournament) => {
        setSelectedTournament(tournament);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTournament(null);
    };

    return (
        <div className="post-manager">
            <div className="header">
                <button className="icon-button" onClick={() => navigate('/create-event')}>
                    <i className="fas fa-plus-circle"></i>
                </button>
                <h1 className="title">Event Manager</h1>
                <button className="icon-button" onClick={() => navigate(-1)}>
                    <i className="fas fa-times-circle"></i>
                </button>
            </div>
    
            <div className="content">
                {tournaments.length === 0 ? (
                    <p className="empty-message">
                        Manage your Events here, create a new one by clicking the plus icon.
                    </p>
                ) : (
                    <ul className="tournament-list">
                        {tournaments.map((tournament) => (
                            <li key={tournament.id} className="tournament-item">
                                <div onClick={() => handleEdit(tournament)} className="tournament-details">
                                    <span className={`status ${tournament.status.toLowerCase()}`}>
                                        {tournament.status.toUpperCase()}
                                    </span>
                                    <h2>{tournament.title}</h2>
                                    <p>{tournament.description}</p>
                                    <p>When: {tournament.formattedDateTime}</p>
                                </div>
                                <div className="button-row">
                                    <div
                                        className="bookings-info"
                                        onClick={() => setSelectedTournament(tournament)}
                                    >
                                        <span>Bookings: {tournament.bookedUsersCount}</span>
                                        <i className="fas fa-info-circle"></i>
                                    </div>
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteTournament(tournament.id)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
    
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <CreateEventView 
                            editMode={!!selectedTournament}
                            eventId={selectedTournament?.id}
                            onClose={closeModal}
                            setActiveView={() => navigate('/postmanager')} // Ensure to update the view after action
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default PostManagerView;
