import React, { useEffect } from 'react';
import Spacer from './Spacer';
import './policy.css';

function Support() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="policy-container py-5">
      <header>
        <h1 className="text-center mb-4">Support</h1>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 col-md-10 col-sm-12">
              <p className="lead">
                <strong>Feedback, Feature request, Help? let us know:</strong>
                <Spacer />                
                Email: <a href="mailto:rack@pool4real.com">rack@pool4real.com</a>
                <Spacer />                
                Call Center: <a href="tel:8182986097">+1 (818) 298-6097</a>
                <Spacer />
                
                <h4 className="text-center mb-4">Frequently Asked Questions</h4>
                <Spacer />

                <strong>Question: Can I find someone to play pool/billiards with here?</strong>
                <br/>    
                Answer: Yes! The app is designed to close the gap between pool players in the real world. It lets you connect with nearby players, send them a message to discuss where to go. The locations of players are just estimations.
                <Spacer />

                <strong>Question: Do you share my exact location with others?</strong>
                <br/>    
                Answer: No, the app just displays your profile as an option to chat or interact with others in the Nearby Players panel. The proximity is just an estimation and it doesn't display your exact location on the map.
                <Spacer />

                <strong>Question: Can I sell my cue or pool table here?</strong>
                <br/>    
                Answer: Yes! The Shoparound feature is a dedicated online store for all pool/billiards related items. It lets you post items for sale and communicate with buyers or even sellers if you find something you need.
                <Spacer />

                <strong>Question: Can I discover new places to play?</strong>
                <br/>
                Answer: Absolutely! The app features a map of pool halls and clubs around you, making it easy to discover new places to play.
                <Spacer />

                <strong>Question: How do I book a tournament or arrange a friendly game?</strong>
                <br/>    
                Answer: You can book tournaments from the available venues by exploring the Map feature. This will show you available venues and events you can join, you can also check the Nearby Players panel and chat with any nearby player in your area. 
                <Spacer />

                <strong>Question: Are there tools for Pool clubs/venues owners and other businesses?</strong>
                <br/>    
                Answer: Yes, Pool 4 Real is also a tool for businesses where they can advertise, post events, manage bookings, and more through the app's business tools.
                <Spacer />

              </p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Support;
