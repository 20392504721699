import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

function NotFound() {
  return (
    <div className="notfound-container">
      <h1>404</h1>
      <h3>Page Not Found</h3>
      <p>We couldn't find the page you're looking for. Please check the URL or go back to the homepage.</p>
      <Link to="/" className="home-link">Return to Home</Link>
    </div>
  );
}

export default NotFound;
